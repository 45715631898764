@import '../../styles//variables';

.bookTile {
    align-items: center;
    background: #FFF;
    border-radius: 15px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.3);
    color: $body-color;
    display: flex;
    font-family: 'Muli', 'sans-serif';
    justify-content: space-between;
    margin: 1rem 0;
    min-height: 100px;
    width: 100%;
    &Inner {
        border: none;
        cursor: pointer;
        display: inline-flex; /* keep the inline nature of buttons */
        align-items: center;
        flex: 1;
        flex-direction: row;
        justify-content: space-between;
        padding: 15px;
        position: relative;
        text-align: left;
    }
}
.bookTitle {
    display: block;
    font-size: 18px;
    font-weight: bold;
}
.bookPublisher {
    font-size: 14px;
    margin-top:5px;
}
.meta {
    display: flex;
    margin-top: 20px;
}
.completeLabel {
    display: inline-flex;
    margin: 0 0 0 15px;
    align-items: center;
}
.bookState {
    align-items: center;
    color: #0097AF;
    display: flex;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    font-size: 10px;
    text-transform: uppercase;
    width: 70px;
}
.bookDelete {
    align-items: center;
    border: 1px solid red;
    border-radius: 15px;
    box-sizing: border-box;
    color: red;
    cursor: pointer;
    display: flex;
    height: 30px;
    justify-content: center;
    margin: 0.25rem 1.5rem 0.25rem 0.25rem;
    padding: 0;
    width: 30px;
    &:hover {
        background: red;
        color: #FFFFFF;
    }
}
.spinnerIcon {
    animation: spin 2s linear infinite;
}

@keyframes spin { 
    from { 
        transform: rotate(0deg); 
    } to { 
        transform: rotate(360deg); 
    }
}