@import '../../styles/variables';
@import '../../styles/mixins';

.panel {
    background: #FFFFFF;
    border-radius: 1rem;
    margin: 1rem 0;
    padding: 2rem;
    text-align: center;
    &Title {
        font-size: 2.4rem;
        font-weight: bold;
        line-height: normal;
        margin: 0;
    }
    &SubTitle {
        font-size: 2rem;
        line-height: normal;
        margin-bottom: 2rem;
    }
}

@include breakpoint-up(sm) {
    .panel {
        background: #FAFAFA;
        border-radius: 4rem;
        max-width: 55rem;
        text-align: left;
    }
}