.list-group {
    .list-item {
        padding: .5rem 0;
        border-bottom: 1px solid lighten($gray-1, 5%);
        position: relative;
        display: flex;
        align-items: center;
        &:last-child {
            border-bottom: none;
        }
        &--disabled {
            opacity: 0.6;
            cursor: default;
            pointer-events: none;
        }
        &--link {
            cursor: pointer;
            &:hover {
                color: $primary;
            }
        }
    }
}