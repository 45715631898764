@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';
.logReading {
    padding: 20px;
}

.logReadingHeader {
    margin-bottom: 20px;
}

.bookFilters {
    background: #FFF;
    border-radius: 15px;
    display: flex;
    font-family: 'Muli', sans-serif;
    width: 100%;
}
.bookFiltersLog {
    background: none;
    display: block;
}
.filter {
    border: none;
    flex: 1;
}

.filterInner {
    border-bottom: 2px solid #E1E1E1;
    color: #c1c1c1;
    font-size: 16px;
    font-weight: 800;
    &Active {
        border-bottom: 2px solid #FFD653;
        color: #4a4a4a;
    }
}

.logReadingField {
    display: flex;
}

.logReadingSubHeader {
	font-size: 16px;
	font-weight: bold;
}

.logReadingComment {
    width: 100%;
}

.logReadingProbWord {
    background: #80b814;
    border-radius: 10px;
    color: #FFF;
	display: block;
	font-size: 14px;
	font-weight: 800;
    margin-right: 10px;
    padding: 5px 10px;
    &Delete {
		appearance: none;
		padding: 0;
		border: none;
		margin-left: .8rem;
		width: 1.6rem;
		height: 1.6rem;
		line-height: 1.3;
		border-radius: 1rem;
		background: rgba(0,0,0,0.2);
		cursor: pointer;
		&:focus {
			outline: none;
		}
		&:hover {
			background: rgba(0,0,0,0.1);
		}
	}
}

.pageNum {
	width: 70px;
}



//switch 

.switch{
	height: 0;
	width: 0;
	visibility: hidden;
}

.switchLabel {
	cursor: pointer;
	text-indent: -9999px;
	width: 50px;
	height: 26px;
	background: grey;
	display: block;
	border-radius: 100px;
	position: relative;
}

.switchLabel:after {
	content: '';
	position: absolute;
	top: 2px;
	left: 3px;
	width: 22px;
	height: 22px;
	background: #fff;
	border-radius: 90px;
	transition: 0.3s;
}

.switch:checked + .switchLabel {
	background: #80b814;
}

.switch:checked + .switchLabel:after {
	left: calc(100% - 3px);
	transform: translateX(-100%);
}

.switchLabel:active:after {
	width: 40px;
}

.styledInput {
	background: #FFF;
	border: 1px solid #C6CBB5;
	border-radius: 10px;
	color: #696868;
	padding: 1px 5px
}

.bookHeading {
	font-size: 24px;
	font-weight: bold;
}

.bookAuthor {
    font-size: 18px;
    font-weight: 400;
}

.logList {
    border-top: 1px solid #E1E1E1;
    margin-top: 20px;
    padding-top: 20px;
}

.logListItem {
    border-bottom: 2px solid #E1E1E1;
    padding: 10px 0;
}

.logListItemInner {
    display: flex;
    justify-content: space-between;
}

.logListComment {
    align-items: center;
    display: flex;
    margin: 10px 0 5px 0;
}

.logText {
    color: #9c9a9a;
}

@include breakpoint-up(sm) {
	.bookFilters {
        display: inline-block;
        transform: translate3d(-15px, 0, 0);
        width: initial;
	}
    .bookFiltersLog {
		display: flex;
		button {
            flex: auto 0;
        }
        &Action {
            margin-left: auto;
            margin-top: 1rem;
        }
	}
}

.justNow {cursor: pointer;}