@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';
.helpText {
    background: #FFF;
    border-radius: 15px;
    padding: 20px;
}
.helpBack {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
}
.searchMain {
    padding-bottom: 95px;
    position: relative;
}
.newBookPanel {
    background: #FFF;
    border-radius: 15px;
    max-width: 700px;
}
.bookResults {
    max-width: 700px;
}
.newBook {
    align-items: center;
    background: #f5f5f5;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    margin-top: 20px;
    display: flex;
    height: 75px;
    line-height: 18px;
    text-align: left;
    width: 100%;
}

.newBookTitle {
    padding: 0 0 0 50px;
    position: relative;
}
.newBookTitleIcon {
    cursor: pointer;  
    position: absolute;
    left: 0;
}
.searchSplit {
    display: flex;
    flex-direction: column;
}

.searchSubTitle {
    font-size: 16px;
    font-weight: bold;
}

.titleSearchHeader {
    flex: 1;
    background: #f5f5f5;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    margin-bottom: 20px;
}

.isbnButton {
    background: #f5f5f5;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    padding: 20px 20px 20px 100px;
    position: relative;
    text-align: left;
}
.isbnButtonLeft {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    left: 0;
    top: 0;
    line-height: 16px;
    position: absolute;
    text-align: center;
    width: 100px;
}

.bookSearchPanel {
    align-items: center;
    background: #f3f3f3;
    border-radius: 15px;
    color: #696868;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: 'Muli', sans-serif;
    margin-top: 20px;
    padding: 20px;
    position: relative;
}
.help {
    text-align: center;
}

.helpSmall {
    display: block;
    font-size: 12px;
}

.searchOptions {
    display: flex;
}
.option {
    background: #FFF;
    border: none;
    border-radius: 15px;
    box-shadow: 0 0 5px rgba(0,0,0,0.3);
    cursor: pointer;
    flex: 1;
    margin: 20px;
    padding: 70px 0;
}
.optionTitle {
    font-size: 18px;
    margin-top: 5px;
}

.closeSearch {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
}
.closeSearchAlt {
    top: 0;
}

.searchForm {
    align-items: center;
    display: flex;
    margin-top: 20px;
    max-width: 350px;
    width: 100%;
}

.searchInput {
    border: 1px solid #C6CBB5;
    border-radius: 15px;
    flex: 1;
    margin-right: 10px;
    padding: 5px 10px;
}

.searchBtn {
    background: #E6E6E7;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    font-weight: bold;
    line-height: 28px;
    height: 30px;
    padding: 0 10px;
}

.searchBtnActive {
    background: #80B814;
    color: #FFF;
}

.scannerHolder {
    margin-top: 20px;
    position: relative;
    video {
        background: #000 url('../../../../../images/loader.svg') center center no-repeat;
        background-size: 40px 40px;
    }
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        height: 100%;
        width: 100%;
        background: url('../../../../../images/scannerOverlay.png') center center no-repeat;
        background-size: cover;
    }
}

.bookFind {
    background: #FFF;
    border-radius: 15px;
    margin: 10px 0;
    padding: 30px 20px 20px 20px;
    position: relative;
}

.searchAgain {
    cursor: pointer;
    font-weight: bold;
    margin-top: 20px;
    text-align: center;
}

.addBookHeader, .addBookField {
    align-items: center;
    display: flex;
}

.addBookField {
    margin-bottom: 20px;
}

.addBookCover {
    height: auto;
    margin-right: 10px;
    width: 70px;
}

.bookHeading {
    font-size: 14px;
}

.bookAuthor {
    font-size: 18px;
    font-weight: 400;
}

.styledSelect {
    border-radius: 10px;
    padding: 2px 5px;
}



.switch{
	height: 0;
	width: 0;
	visibility: hidden;
}

.switchLabel {
	cursor: pointer;
	text-indent: -9999px;
	width: 50px;
	height: 26px;
	background: grey;
	display: block;
	border-radius: 100px;
	position: relative;
}

.switchLabel:after {
	content: '';
	position: absolute;
	top: 2px;
	left: 3px;
	width: 22px;
	height: 22px;
	background: #fff;
	border-radius: 90px;
	transition: 0.3s;
}

.switch:checked + .switchLabel {
	background: #80b814;
}

.switch:checked + .switchLabel:after {
	left: calc(100% - 3px);
	transform: translateX(-100%);
}

.switchLabel:active:after {
	width: 40px;
}

.styledInput {
	background: #FFF;
	border: 1px solid #C6CBB5;
	border-radius: 10px;
	color: #696868;
	padding: 1px 5px
}


.resultBook {
    align-items: center;
    background: #FFFFFF;
    border-radius: 15px;
    box-shadow: 0 1px 5px rgba(0,0,0,0.3);
    display: flex;
    font-family: 'Muli', sans-serif;
    margin-top: 20px;
    padding: 10px;
    position: relative;
}

.resultMeta {
    flex: 1;
}

.resultBookImage {
    height: auto;
    margin-right: 10px;
    width: 60px;
}

.resultBookImageError
{
    margin-left: 15px;
    width: 30px;
    margin-right: 25px;
}

.resultBookTitle {
    display: block;
    font-size: 14px;
    font-weight: 800;
}

.resultBookAuthor {
    font-size: 16px;
    margin-bottom: 5px;
}

.resultBookCode {
    color: #b1afaf;
    font-size: 12px;
}
.bookModal {
    background: #FAFAFA;
    border-top-left-radius: 15px;
    box-shadow: 0px 0px 10px 10px rgba(0,0,0,0.5);
    font-family: 'Muli', sans-serif;
    height: calc(100vh - 20px);
    max-height: calc(100vh - 20px);
    overflow-y: scroll;
    padding: 20px;
    position: fixed;
    top: 20px;
    left: 20px;
    transform: translate3d(100vw, 0, 0);
    transition: transform .3s cubic-bezier(0, .52, 0, 1);
    width: calc(100vw - 20px);
    z-index: 400;
}

.bookModalOpen {
    transform: translate3d(0vw, 0, 0); 
}

.closeBook {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 302;
}

.closeBookAlt {
    top: 40px;
    position: fixed;
    z-index: 401;
}

@include breakpoint-down(xs) {
    .newBookPanel {
        padding: 20px;
    }
    .bookSearchPanelISBN {
        box-shadow: 0 0 5px rgba(0,0,0,0.3);
        position: fixed;
        display: block;
        top: 5px;
        left: 5px;
        overflow-y: scroll;
        margin-top: 0;
        bottom: 5px;
        right: 5px;
        z-index: 400;
    }
    .scannerHolder {
        margin: 20px auto;
        width: 350px;
    }
    .searchForm {
        max-width: 350px;
        margin: 15px auto 0 auto;
        width: 100%;
    }
    .scannerHolder {
        &::after {
            background: url('../../../../../images/scannerOverlayMob.png') center center no-repeat;
        }
    }
    .addTitle {
        color: #FFF;
    }
}
@include breakpoint-up(md) {
    .searchSplit {
        flex-direction: row;
    }
    .titleSearchHeader {
        margin-bottom: 0;
        margin-right: 20px;
    }
    .newBook {
        background: #FFF;
        border-radius: 0;
        bottom: 0;
        box-shadow: 0 -2px 3px rgba(0,0,0,0.2);
        left: 100px;
        position: fixed;
        margin-top: 0;
        right: 0;
        width: calc(100% - 100px);
    }
    .helpText {
        padding: 0;
        max-width: 700px;
    }
}
@include breakpoint-up(lg) {
    .bookModal {
        left: initial;
        right: 0;
        width: 50vw;
    }

    .resultBook {
        max-width: 50%;
    }
}