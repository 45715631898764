@import '../../styles/variables';
@import '../../styles/mixins';

.signUp {
    &AppPanel {
        background: lighten($gray-1, 6%);
        margin: 0 -20px;
        padding: 20px;
    }
    &AppLinks {
        align-items: center;
        display: flex;
        justify-content: center;
        margin: 0 -20px;
    }
    &Apple,
    &Android {
        align-items: center;
        justify-content: center;
        margin: 0 10px;
    }

    &Title,
    &Subtitle {
        font-weight: bold;
        font-size: 2rem;
        margin: 1rem auto 2rem auto;
    }
    &Subtitle {
        margin-top: 0;
    }
    &Text,
    &Subtitle {
        text-align: center;
    }
}

/*.buttonImage {
    position: absolute;
}

.signup {
    &Captcha {
        margin: 0 auto 2rem auto;
        text-align: center;
    }
}*/
.videoPanel {
    margin-top: 20px;
}

.fbLogin {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 15px;
    margin-bottom: 15px;
}
.fbLoginSpan {
    position: relative;
    background: #fafafa;
    padding: 0 10px;
    z-index: 2;
}
.fbLogin::before {
    content: '';
    width: 100%;
    height: 1px;
    background: #CCC;
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 0;
}

.closeVideo {display: none}

@include breakpoint-up(sm) {
    .loginMethods {
        display: flex;
        margin: 0 -20px;
        &Form {
            flex: 5;
            margin: 0 20px;
        }
        &Fb {
            flex: 4;
            margin: 0 20px;
        }
    }
}
.loginMethods {
    justify-content: center;
    &Form {
        max-width: 500px;
    }
}
@include breakpoint-up(md) {
    // .loginMethods {
    //     display: flex;
    //     margin: 0 -20px;
    //     &Form {
    //         flex: 5;
    //         margin: 0 20px;
    //     }
    //     &Fb {
    //         flex: 4;
    //         margin: 0 20px;
    //     }
    // }
    .signUp {
        &Text,
        &Subtitle {
            text-align: left !important;
        }
        &AppPanel {
            display: flex;
            padding: 20px 0 10px;
        }
        &AppLinks {
            flex: 4;
            margin: 0 20px;
        }
        &AppText {
            flex: 5;
            margin: 0 20px;
        }
        &Apple,
        &Android {
            display: flex;
            flex: 1;
            justify-content: flex-start;
            margin: 0 5px 0 0;
        }
        &Android {
            justify-content: flex-end;
            margin: 0 0 0 5px;
        }
    }
}

@include breakpoint-up(lg) {
.closeBg {
    background: rgba(0,0,0,0.2);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    position: fixed;
    z-index: 499;
}
.videoPanel {
    background: #FFF;
    box-shadow: 0 2px 5px rgba(0,0,0,0.3);
    padding: 10px 20px 20px 20px;
    position: fixed;
    height: auto;
    left: 20px;
    margin-top: 0;
    width: calc(100% - 40px);
    z-index: 500;
}
.closeVideo {
    display: block;
    cursor: pointer;
    float: right;
    margin-bottom: 10px;
}
}


@include breakpoint-up(xl) {
    .videoPanel {
        left: calc(50% - 550px);
        width: 1100px;
    }
}