@import '../../styles/variables';
@import '../../styles/mixins';

.alertInfo {
    padding: 1rem 2rem;
    border-radius: 1rem;
    background: $gray-2;
    margin-bottom: 2rem;
}
.wtrBanner {
    border-radius: 2rem;
    background: linear-gradient(#20364B, #1D2671);
    color: #fff;
    display: flex;
    margin-bottom: 3rem;
    margin-left: 1rem;
    align-items: center;
    position: relative;
        padding: 1.4rem;
    &Img {
        margin-right: 2rem;
        max-width: 12rem;
        position: absolute;
        left: -1rem;
        bottom: 0rem;
        width: 12rem;

    }
    &Content {
        padding-left: 12rem;
    }
    &Logo {
        max-width: 29rem;
    }
    &Title,
    &Subtitle {
        margin-bottom: 0;
        font-weight: bold;
    }
    &Title {
        font-size: 2.4rem;
    }
    &Subtitle {
        font-size: 1.8rem;
    }
}

.bookInfo {
    display: flex;
    margin-bottom: 4rem;

    &Cover {
        height: 12.8rem;
        margin-right: 2rem;
    }

    &Details {
        line-height: normal;
        display: flex;
        flex-direction: column;
        flex: 1;

        &Title,
        &Featuring {
            margin-bottom: .5rem;
        }

        &Title {
            font-size: 2.4rem;
            font-weight: bold;
        }

        &Featuring,
        &Copies {
            font-size: 2.4rem;
        }

        &Copies {
            margin-top: auto;
            &Main {
                display: flex;
                align-items: center;
                padding-left: 1rem;
                input {
                  width: 6rem;
                  max-width: 6rem;
                }
            }
        }

        &Price {
            margin-left: .5rem;
        }

        &Blurb {
            font-size: 1.6rem;
        }
    }
}

.bookWtrSent {
    border-top: 1px solid #979797;
    border-bottom: 1px solid #979797;
    padding: 1.2rem 0;
    margin-bottom: 2rem;
}
.wtrCopies {
    background: #EDEDED;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &Raise {
        background: linear-gradient(#20364B, #1D2671);
        color: #fff;
        width: 30rem;
        display: flex;
        align-items: center;
        font-size: 2.4rem;
        font-weight: bold;
        justify-content: space-between;
        border-radius: 1rem;
            padding: 1rem;
        span {
            color: #FFD653;
        }
    }
}

@include breakpoint-up(lg) {
    .bookInfo {
            &Cover {
                max-width: 10rem;
                margin-right: 1.5rem;
            }
        }
}

.wtrOrderConfirmed {
    max-width: 45rem;
    margin: auto;
    &Img {
        margin: 0 auto 3rem auto;
        max-width: 16rem;
    }
    &Title,
    &Subtitle {
        font-weight: bold;
        font-size: 2.8rem;
    }
    &Subtitle {
        font-size: 2.4rem;
    }
}