@import '../../styles/mixins';

.contentHolder {
    max-width: 600px;
}
.profileTabs {
    display: flex;
    margin-bottom: 20px;
}

.profileTab {
    color: #FFF;
    display: block;
    padding: 10px;
}

.profileTabActive {
    background: #FFF;
    color: #4a4a4a;
    border-radius: 5px;
}

.settingPanel {
    display: block;
    background: #F5F5F5;
    border-radius: 20px;
    margin-bottom: 4rem;
    padding: 2rem 4rem 2rem 2rem;
    position: relative;
    width: 100%;
}

.settingPanelIcon {
    position: absolute;
    right: 10px;
    top: calc(50% - 12px);
}

.settingPanelEmail {
    cursor: pointer;
    &:hover {
        background: darken(#F5F5F5, 5%)
    }
}

.fbSettingPanel {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.fbSettingImg {
    height: auto;
    margin: 20px auto;
    width: 120px;
}

@include breakpoint-up(sm) {
    .profileTabs {
        border-bottom: 1px solid #C7C7C7;
    }
    
    .profileTab {
        border-radius: 0;
        color: #4a4a4a;
    }
    
    .profileTabActive {
        background: #E9E9E9;
    }
}

@include breakpoint-up(lg) {

    .form {
        margin: 0 20px;
        min-width: 305px;
    }
}