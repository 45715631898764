@import '../../styles/variables';
@import '../../styles/mixins';

.childContainer {
    color: white;
    display: flex;
    flex-direction: column;
    &Right {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }
    input[type=checkbox]{
        display: none;
        height: 0;
        width: 0;
        visibility: hidden;
    }
    input:checked + label {
        background: #80b814;
    }
    input:checked + label:after {
        left: calc(100% - 3px);
        transform: translateX(-100%);
    }
}
.bookSubHeading {
    cursor: pointer;
    font-size: 18px;
    font-weight: normal;
    line-height: 18px;
    padding-left: 24px;
    position: relative;
}
.editHeader,.editMeta {
    display: flex;
    color: #716F6F;
}
.editPanel {
    background: #F3F3F3;
    border-radius: 15px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.3);
    padding: 15px;
}
.noEntry {
    font-style: italic;
    font-size: 14px;
}
.editMeta {
    align-items: center;
}
.editDate {
    margin-left: auto;
}
.bookSubHeadingIcon {
    position: absolute;
    left: 0;
    top: -4px;
}
.childInfo {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;
    &Lower {
        align-items: center;
        display: flex;
        flex-direction: column;
    }
    &Login {
        font-size: 1.4rem;
        margin-top: 1rem;
        text-align: center;
    }
}

.profileImg {
    border: 1px solid white;
    border-radius: 7rem;
    height: 14rem;
    padding: 0.5rem;
    width: 14rem;
}
.profileImgHolder {
    min-width: 14rem;
    text-align: center;
}

.childName {
    font-size: 2.4rem;
    font-weight: bold;
    line-height: normal;
    margin: 1rem 0 0;
}

.gemPanel {
    background: #FFF;
    border-radius: 4rem;
    color: #4a4a4a;
    display: inline-flex;
    font-size: 1.8rem;
    height: 5rem;
    line-height: 5rem;
    margin-top: 1rem;
    padding: 0 2rem;
}

.gem {
    height: auto;
    margin-right: 0.5rem;
    width: 3rem;
}
.addBookBtn {
    box-shadow: 0rem 0.1rem 0.5rem rgba(0, 0, 0, 0.2);
    background: #FFF;
    border-radius: 1000px;
    border: none;
    cursor: pointer;
    padding: 0;
    display: block;
    padding: 5px;
    height: 50px;
    width: 50px;
    position: absolute;
    right: 10px;
}
.section {
    &Title {
        align-items: center;
        display: flex;
        position: relative;
        &Text {
            font-size: 2rem;
            font-weight: bold;
            margin: 0;
            text-align: center;
        }
        &Img {
            margin-right: .5rem;
            width: 4rem;
            max-height: 4rem;
        }
        &Read {
            margin-bottom: 20px;
        }
    }
    margin-bottom: 4rem;
    max-width: 60rem;
}

.book {
    background: #FFFFFF;
    border-radius: 1rem;
    border: none;
    box-shadow: 0rem 0.1rem 0.5rem rgba(0, 0, 0, 0.2);
    color: #3A3A3A;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
    max-width: 600px;
    min-height: 10rem;
    padding: 1rem;
    text-align: left;
    width: 100%;
    &Cover {
        height: auto;
        width: 6rem;
    }
    &Left {
        display: flex;
        flex-direction: column;
    }
    
    &Title {
        color: #696868;
        font-size: 1.2rem;
        font-weight: bold;
        &Writing {
            font-size: 1.6rem;
        } 
    }
    &LastUpdate {
        align-items: center;
        display: flex;
        font-size: 1.2rem;
        margin-top: auto;
        &Icon {
            margin-right: .5rem;
        }
    }
}

.reviewBlock {
    align-items: center;
    display: flex;
    margin: 10px 0 20px 0;
}
.reviewImg {
    li {
        display: inline-block;
        margin-right: .5rem;
    }
    img {
        width: 3.6rem;
    }
}
.reviewBody {
    flex: 1;
    margin-left: 10px;
}
.reviewHeader {
    font-size: 16px;
    font-weight: bold;
}

.subtitle {
    flex: 1;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
}

.appsHolder {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.5rem 2rem;
}

.appLinks {
    display: flex;
    flex: 0 0 100%;
}

.appLink {
    background: white;
    border-radius: 1rem;
    display: flex;
    flex: 1;
    justify-content: center;
    margin-right: 0.5rem;
    padding: 2rem;
    &:hover {
        background: $gray-1;
    }
    &:last-child {
        margin: 0 0 0 0.5rem;
    }
}

.schoolPanel {
    align-items: center;
    background: white;
    border-radius: 1rem;
    color: $body-color;
    display: flex;
    justify-content: flex-start;
    padding: 1rem;
    width: 100%;
    &:hover {
        background: $gray-1;
    }
    &Disabled {
        pointer-events: none;
    }
    &Img {
        border-radius: .5rem;
        max-height: 6.5rem;
        max-width: 6.5rem;
    }
    &Name {
        font-size: 1.6rem;
        
    }
    &Text {
        margin-left: 1rem;
    }
}

.noMobile {
    display: none;
}

.recentWordList {
    display: flex;
}
.recentWord {
    background: #80b814;
    border-radius: 10px;
    color: #FFF;
    display: block;
    font-size: 14px;
    font-weight: 800;
    margin-right: 10px;
    padding: 5px 10px;
}
.noResults {
    font-size: 1.4rem;
    font-weight: bold;
}

.bookBg {
    background: rgba(0, 0, 0, 0.2);
    bottom: 0;
    display: none;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99;
}
.bookBgShow {
    display: block;
}

.bookModal {
    background: #FAFAFA;
    border-top-left-radius: 15px;
    box-shadow: 0px 0px 10px 10px rgba(0,0,0,0.5);
    font-family: 'Muli', sans-serif;
    height: calc(100vh - 20px);
    max-height: calc(100vh - 20px);
    max-width: 700px;
    overflow-y: scroll;
    padding: 20px 20px 40px 20px;
    position: fixed;
    top: 20px;
    left: 20px;
    transform: translate3d(100vw, 0, 0);
    transition: transform .3s cubic-bezier(0, .52, 0, 1);
    width: calc(100vw - 20px);
    z-index: 100;
}

.closeBook {
    cursor: pointer;
    position: fixed;
    right: 10px;
    top: 30px;
    z-index: 300;
}

.bookModalOpen {
    transform: translate3d(0vw, 0, 0); 
}

.readingGuideLink {
    background: #FFF;
    border-radius: 15px;
    color: $primary-dark;
    display: block;
    font-size: 14px;
    font-weight: bold;
    padding: 10px 15px;
    margin: 10px 0;
    &:hover {background: #F2F2F2}
}
.readingGuideSchool {
    display: block;
    font-size: 18px;
    font-weight: normal;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.btnReread {
    color: $primary;
    position: relative;
    font-weight: bold;
    padding-left: 2.4rem!important;
    &:hover {
        color: $gray-3;
    }
    svg {
        position: absolute;
        left: 0;
        top: 1px;
    }
}

.logListItem {
    border-bottom: 1px solid #E1E1E1;
    padding: 1.5rem 3.5rem 1.5rem 2rem;
    cursor: pointer;
    position: relative;
    &:nth-child(odd) {
        background: #fff;
    }
}

.logListItemCantEdit {
    cursor: default;
    padding: 1.5rem 2rem;
}

.logListItemCog {
    color: #BBB;
    height: 20px;
    position: absolute;
    right: 5px;
    top: 15px;
}

.logListItem:hover .logListItemCog {
    color: darken(#BBB, 10%);
}

.logListItemHeader {
    display: flex;
    justify-content: space-between;
    &Main {
        display: flex;
        flex: 1;
    }
    &Date {
        flex-basis: 10rem;
        flex-shrink: 0;
    }
    &Pages {
        display: flex;
        align-items: center;
        svg {
            margin-right: .6rem;
        }
    }
}
.logListSection {
    margin-top: 1.2rem;
}

.wordLabel {
    display: inline-block;
    vertical-align: middle;
    border-radius: .5rem;
    padding: .3rem 1.2rem;
    min-width: 4rem;
    text-align: center;
    white-space: nowrap;
    font-weight: 400;
    font-size: 1.4rem;
    background: lighten($gray-3, 10%);
    color: #fff;
    background: #646464;
}

.readingGuideBtn {
    background: #696868;
    border-radius: 15px;
    color: #FFF;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 250px;
    padding: 5px 10px;
    margin-top: 20px;
    text-transform: uppercase;
}
.headerNavLink {
    color: #FFF;
}
@include breakpoint-up(sm) {
    .headerNavLink {
        color: #000;
    }
    .childContainer {
        color: $body-color;
        &Right {
            align-items: flex-start;
        }
    }

    
    .readingGuideLink {
        background: $primary-dark;
        color: #FFF;
        &:hover {background: darken($primary-dark, 5%)}
    }
    .profileImg {
        border-color: $gray-1;
    }

    .profileImgHolder {
        margin-right: 1rem;
    }

    .childInfo {
        flex-direction: row;
        &Lower {
            align-items: flex-start;
        }
        &Login {
            margin-top: 0;
            margin-left: auto;
            text-align: left;
            &Title {
                color: darken($gray-2, 20%);
            }
        }
    }

    .gemPanel {
        background: #FaFaFa;
        box-shadow: 0rem 0.1rem 0.5rem rgba(0, 0, 0, 0.2);
    }

    .subtitle {
        text-align: left;
    }
    
    .noMobile {
        display: block;
        width: 100%;
    }

    .schoolPanel,
    .appLink {
        align-items: center;
        border: 1px solid $gray-1;
    }

    .schoolPanel {
        line-height: normal;
        &Name {
            font-size: 2.4rem;
        }
        margin-bottom: 2rem;
    }

    .classes {
        margin-bottom: 1.5rem;
    }

    .studentClass {
        align-items: center;
        border: 1px solid $gray-1;
        border-radius: 1rem;
        color: $body-color;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        line-height: normal;
        margin-bottom: .5rem;
        padding: 1rem;
        &Left {
            align-items: center;
            display: flex;
        }
        &Name {
            font-size: 2.4rem;
            line-height: normal;
            margin-left: 1rem;
        }
        &Description {
            font-size: 1.2rem;
        }
        &:hover {
            background: $gray-1;
        }
    }
    .bookModal {
        left: initial;
        padding: 20px;
        right: 0;
    }

    .section {
        &Title {
            background: #F2F2F2;
            border-radius: 4rem;
            padding: 1.5rem 2rem;
            &Text {
                color: $body-color;
            }
            &Read {
                margin-bottom: initial;
            }
        }
    }
}

@include breakpoint-up(md) {
    .childContainer {
        align-items: flex-start;
        flex-direction: row;
        &Right {
            flex: 4;
        }
        &Left {
            flex: 3;
            margin-right: 4rem;
        }
        &LeftExt {flex: 6}
        &Main {
            max-width: 40rem;
            width: 100%;
        }
        max-width: 100rem;
    }
    .mobileTitle {
        display: none;
    }
}

@include breakpoint-up(lg) {
    .sections {
        display: flex;
        margin: 0 -1rem;
    }

    .section {
        flex: 1;
        margin: 0 1rem;
    }
}