.typeLabel,
.typeLabelAlt,
.typeLabelGroup {
    background: #0097AF;
    border-radius: 10px;
    color: #FFF;
    display: inline-block;
    font-size: 14px;
    padding: 5px 15px;
}

.typeLabelAlt {
    background: #ABAD9A;
}
.typeLabelGroup {
    background: #1D2671;
}