@import '../../styles/variables';
@import '../../styles/mixins';

.banner {
    background: #f1f1f1;
    text-align: center;
    margin: 20px -20px 20px -20px;
    font-size: 18px;
    padding: 20px;
    width: calc(100% + 40px);
}
.bannerHeading {
    font-size: 24px;
    font-weight: bold;
}
.bannerPara {
    line-height: 22px;
    max-width: 960px;
    margin: 0 auto;
}

@include breakpoint-up(sm) {
    .banner {
        margin: -20px -30px 20px -30px;
        width: calc(100% + 60px);
    }
    .bannerPublic {
        margin: 20px -30px 20px -30px;
    }
}

@include breakpoint-up(lg) {
    .bannerPublic {
        margin: 0 -30px 20px -30px;
    }
}

.desktopTotals {
    display: none;
}

.bookPileMobile {
    margin-bottom: 1rem;
    max-width: 38.1rem;
    width: 100%;
}
.codeExample {
    max-width: 27.2rem;
    width: 100%;
}

.backToStore {
    margin-bottom: 2rem;
    color: $body-color;
    text-decoration: underline;
    display: flex;
    align-items: center;

    svg {
        margin-right: .5rem;
    }
}


.cardImages {
    align-items: center;
    display: flex;
    margin: 2rem -.5rem 0;
    &Img {
        margin: 0 .5rem;
        height: 4rem;
        &Stripe {
            height: 2.6rem;
        }
    }
}

.orderTotals {
    margin-bottom: 3rem;
    &Item {
        display: flex;
        margin-bottom: .8rem;
        justify-content: space-between;
        &Last {
            font-weight: bold;
            border-bottom: 1px solid #979797;
            padding-bottom: 1rem;
        }
    }
}
.contactPanel {
    border-radius: 1rem;
    padding: 2rem;
    background: #FAFAFA;
    margin-bottom: 2rem;
}

.orderConfirmPage {
    text-align: center !important;
    &Icon {
        color: $primary-green;
        height: 10rem;
        width: 10rem;
    }
    p {
        font-size: 1.6rem;
    }
}

.processing {
    align-items: center;
    background: rgba(255,255,255,0.7);
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    pointer-events: none;
    position: fixed;
    &Loader {
        height: 10rem;
        width: 10rem;
        animation: spin 2s linear infinite;
    }
    &Text {
        font-size: 2rem;
    }
}

.codePage {
    max-width: 100rem;
    &Right {
        display: none;
    }
    &TitleMobile,
    &Title {
        color: #FFFFFF;
        font-weight: bold;
    }
    &TitleDesktop {
        display: none;
    }
}

.orderBook {
    max-width: 100rem;
}

.storeBook {
    color: $body-color;
    margin-bottom: 1rem;
    padding: 1rem;
    &:hover {
        background: $gray-1;
    }
    &Author {
        align-items: center;
        display: flex;
        margin-bottom: .5rem;
        &Avatar {
            border-radius: 1.2rem;
            height: 2.4rem;
            margin-right: .5rem;
            width: 2.4rem;
        }
        &Name {
            color: #696868;
            font-size: 1.2rem;
            font-weight: bold;
        }
    }
    &Details {
        display: flex;
        justify-content: space-between;
        &Cover {
            height: 8rem;
            margin-left: 1rem;
        }
        &Title {
            color: #696868;
            font-size: 1.6rem;
            font-weight: bold;
        }
        &Text {
            font-size: 1.2rem;
        }
    }
    &Link {
        align-items: center;
        align-self: flex-end;
        display: flex;
        font-size: 1.6rem;
        font-weight: bold;
        margin-top: 0.5rem;
    }
}

@keyframes spin { 
    from { 
        transform: rotate(0deg); 
    } to { 
        transform: rotate(360deg); 
    }
}

@include breakpoint-up(lg) {
    .mobileTotals {
        display: none;
    }
    .desktopTotals {
        display: block;
    }
    .codePage {
        display: inline-flex;
        margin: 0 -1rem;
        &Left,
        &LeftLoggedIn {
            display: block;
            flex: 1;
            margin: 0 1rem;
        }
        &TitleMobile {
            display: none;
        }
        &TitleDesktop,
        &Title {
            color: $body-color;
            display: block;
            font-weight: normal;
        }
    }
    .bookPileMobile {
        display: none;
    }
    .orderBook {
        display: flex;
        flex-direction: row-reverse;
        margin: 0 -1rem;
        padding: 0;
        &Left,
        &Right {
            flex: 1;
            margin: 0 1rem;
        }
    }
}