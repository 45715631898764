@import '../../styles/variables';
@import '../../styles/mixins';
.dashContainer {
    display: flex;
}
.child {
    align-items: center;
    background: #FFFFFF;
    border-radius: 4rem;
    display: flex;
    margin: 1rem 0;
    padding: 1.6rem;
    min-width: 400px;
    &Avatar {
        border-radius: 5rem;
        margin-right: 2rem;
        width: 8rem;
    }
    &Name {
        font-size: 2.4rem;
        font-weight: bold;
    }
    &Disabled {
        background: rgba(0,0,0,0.15);
        &:first-of-type {
            margin-top: 2rem;
        }
    }
}

.callout {
    background: #E2F2D0;
    border: 1px solid #417505;
    color: #3A3A3A;
    display: block;
    margin-bottom: 20px;
    padding: 5px;
    position: relative;
    max-width: 800px;
    text-align: center;
}
.callout:hover {
    background: darken(#E2F2D0, 10%)
}
.calloutIcon {
    position: absolute;
    right: 5px;
}

@include breakpoint-up(sm) {
    .child {
        border-radius: 1rem;
        box-shadow: 0rem 0.1rem 0.5rem rgba(0, 0, 0, 0.2);
        max-width: 55rem;
        &Disabled {
            box-shadow: none;
        }
    }
    .callout {
        text-align: left;
    }
}

