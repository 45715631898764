@import '../../styles/variables';

.pending {
    &Title {
        font-size: 2rem;
        font-weight: bold;
        text-align: center;
        margin: 2rem;
    }
    p {
        font-size: 1.4rem;
        line-height: normal;
        padding: 0 2rem;
        &.pendingSubTitle {
            color: #696868;
            font-size: 1.6rem;
            font-weight: bold;
            margin: 2rem 0 1rem;
        }
    }
    &MailImg {
        margin-bottom: 2rem;
    }
    button {
        font-size: 1.4rem;
    }
    &ContactLink {
        align-items: center;
        display: inline-flex;
        font-size: 1.4rem;
        margin: .5rem 2rem;
        span {
            margin-left: .75rem;
        }
        &:hover {
            color: darken($primary, 10%);
        }
    }
}