.auth-page {
    &__container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        max-width: 75rem;
        margin: auto;
        &:before {
            position: fixed;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            content: '';
            background: linear-gradient($primary-dark, $primary) no-repeat left top;
            background-size: cover;
            z-index: -1;
        }
    }
    &__panel {
        background: #FAFAFA;
        border-radius: 40px;
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        margin: 85px 20px 20px;
        max-width: 750px;
        padding: 0 20px 40px;
        &--no-pad {
            padding: 0 0 40px;
        }
    }

    &__logo {
        margin: auto;
        max-width: 12rem;
        margin-top: -50px;
    }

    &__title {
        margin: 20px 0;
        text-align: center;
        text-transform: uppercase;
    }

    &__title-light {
        margin: 20px 0;
        text-align: center;
    }
    &__subtitle {
        text-align: center;
        font-size: 20px;
    }
    &__extra {
        text-align: center;
        color: #fff;
        a {
            background: #fff;
            border-radius: .5rem;
            text-transform: uppercase;
            padding: .5rem 1rem;
            margin: 1rem .8rem;
            font-weight: bold;
            display: inline-block;
            &:hover {
                background: #f2f2f2;
            }
        }
    }

    &__link {
        cursor: pointer;
        &:hover {
            color: lighten($body-color, 10%);
        }
    }

    &__confirm-email {
        margin-bottom: 2rem;
        img {
            margin: 0 -2rem;
            position: relative;
            width: calc(100% + 4rem);
            max-width: none;
        }
    }
    
    &__logged-out {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        &__icon {
            height: 42px;
            width: 42px;
        }
        &__text {
            font-size: 16px;
            font-weight: lighter;
            margin-bottom: 0;
            margin-left: 10px;
        }
    }
}

.kep-login-facebook{
    font-family:Helvetica,sans-serif;
    font-weight:700;
    -webkit-font-smoothing:antialiased;
    color:#fff;cursor:pointer;
    display:inline-block;
    font-size:16px;
    text-decoration:none;
    transition:background-color .3s,border-color .3s;background-color:#4c69ba;
    border:calc(.06887vw + .67769px) solid #4c69ba;
    padding:calc(.34435vw + 13.38843px) calc(.34435vw + 18.38843px);
    width: 100%;
}

.kep-login-facebook.metro {
    border-radius: 10px !important;
}
.kep-login-facebook.small {
    padding:10px 20px
}
.kep-login-facebook .fa{
    margin-right:calc(.34435vw + 3.38843px)
}



.login-header {
    position: relative;
    margin-left: -20px;
    margin-right: -20px;
}
.login {
    &__mobile-toggle {
        display: flex;
        align-items: center;
        background: #EEEEEE;
        padding: 1rem;
        border-radius: 2rem 2rem 0 0;
        cursor: pointer;
        margin-bottom: 2rem;
        span {
            display: block;
            font-weight: bold;
            margin-left: .5rem;
        }
        svg {
            margin-left: auto;
        }
    }
    &__mobile {
        display: none;
        position: absolute;
        top: 4rem;
        width: 100%;
        z-index: 2;
        box-shadow: 0 0.2rem .3rem 0 rgba(0, 0, 0, 0.16);
        text-align: left;
        &--visible {
            display: block;
        }
        &__link {
            background: #EEEEEE;
            padding: .6rem 1rem;
            border-top: 1px solid lighten(#ccc, 5%);
            a {
                color: $body-color;
            }
            span {
                font-weight: bold;
            }
        }
    }
    &__opts {
        display: none;
        justify-content: space-between;
        flex-direction: column;
        border-radius: 3rem 3rem 0 0; 
        margin-bottom: 2rem;  
        &__link {
            flex: 1;
            background: #EEEEEE;
            padding: 1rem;
            position: relative;
            line-height: 1.4;
            font-size: 1.8rem;
            a {
                color: lighten($body-color, 10%);
            }
            span {
                display: block;
                font-weight: bold;
                margin-left: .5rem;
            }
            &:first-of-type {
                border-radius: 3rem 3rem 0 0;
            }
    
            &--active {
                color: #fff!important;
                a {
                    color: #fff;
                }
                &:after {
                    content: '';
                    display: block;
                    width: 0;
                    height: 0;
                    position: absolute;
                    top: 100%;
                    left: calc(50% - 1rem);
                    border-left: 1rem solid transparent;
                    border-right: 1rem solid transparent;
                    border-top: 1rem solid #1D2671;
                }
            }
        }
    }
}



@include breakpoint-up(sm) {
    .login {
        &__mobile-toggle,
        &__mobile {
            display: none;
        }
        &__opts {
           flex-direction: row;
           display: flex;
           &__link {
                text-align: center;
                display: block;
                span {
                    font-size: 2.2rem;
                    margin-left: 0;
                }
                &--active {
                    background: #1D2671;
                }
                &:first-of-type {
                    border-radius: 3rem 0 0 0;
                    border-right: 1px solid #ccc;
                }
                &:last-of-type {
                    border-radius: 0 3rem 0 0;
                    border-left: 1px solid #ccc;
                }
           }
        }
    }
}