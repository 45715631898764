@import '../../styles/variables';
@import '../../styles/mixins';

.nav {
    background: #FFFFFF;
    bottom: 0;
    height: 11rem;
    position: fixed;
    width: 100%;
    text-align: center;
    padding: 0 1rem 1rem 1rem;
    font-size: 1.2rem;
    z-index: 10;
    &User {
        display: none;
        a {
            color: #fff;
            display: block;
            overflow-wrap: break-word;
            &:hover {
                color: $primary-light;
            }
        }
        img {
            margin: 0 auto .5rem auto;
            width: 5rem;
            height: auto;
            border-radius: 5rem;
        }
        &Name {
            text-transform: capitalize;
            margin-bottom: 1rem;
        }
    }
    &Exit {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        display: none;
    }
    &Brand {
        display: none;
        img {
            width: 5rem;
            margin: 0 auto 1.5rem auto;
        }
    }
    &Logout {
        padding: 0;
        border: none;
        color: #fff;
        margin-bottom: 1rem;
        cursor: pointer;
    }
    &Menu {
        list-style: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        &Link {
            display: flex;
            color: lighten($body-color, 10%);
            align-items: center;
            flex-direction: column;
            margin: 0 1.5rem;
            &Icon {
                padding: 1.2rem 1rem .8rem 1rem;
                border-radius: 0 0 1rem 1rem;
                margin-bottom: .8rem;
            }
            &Active,
            &:hover {
                i {
                    background: $primary;
                    color: #fff;
                }
            }
        }
    }
}


@include breakpoint-up(sm) {
    .nav {
        left: 0;
        height: 100%;
        width: 10rem;
        background: linear-gradient($primary-dark, $primary) no-repeat left top;
        color: #fff;
        padding: 1rem;
        &User,
        &Brand,
        &Exit {
            display: block;
        }
        &Menu {
            flex-direction: column;
            padding: 0;
            &Link {
                color: #fff;
                margin: 1rem 0;
                &Icon {
                    padding: 0;
                    margin: 0;
                }
                &Active,
                &:hover {
                    color: $primary-light;
                    i {
                        background: none;
                        color: $primary-light;
                    }
                }
            }
        }
    }
}
