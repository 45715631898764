@import '../../styles/variables';
@import '../../styles/mixins';




.storePage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    padding: 2rem;
    &:before {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        content: '';
        background: linear-gradient($primary-dark, $primary) no-repeat left top;
        background-size: cover;
        z-index: -1;
    }
    &Header {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;
        &Left {
            align-items: center;
            display: flex;
        }
        &Title {
            color: #FFFFFF;
            font-size: 2.4rem;
            font-weight: bold;
        }
    }
    &Logo {
       
        margin-right: .5rem;
       max-width: 100px;
    }
    &Exit {
        color: #FFFFFF;
        font-size: 2.4rem;
        font-weight: bold;
        &:hover {
            color: #D4D4D4;
        }
    }
    &Content {
        background: #FFFFFF;
        border-radius: 1rem;
        margin: 2rem 0;
        padding: 4rem 2rem;
    }
}

.desktopTotals {
    display: none;
}


.bookPileMobile {
    margin-bottom: 1rem;
    max-width: 38.1rem;
    width: 100%;
}
.codeExample {
    max-width: 27.2rem;
    width: 100%;
}

.backToStore {
    margin-bottom: 2rem;
    color: $body-color;
    text-decoration: underline;
    display: flex;
    align-items: center;
    svg {
        margin-right: .5rem;
    }
}


.cardImages {
    align-items: center;
    display: flex;
    margin: 2rem -.5rem 0;
    &Img {
        margin: 0 .5rem;
        height: 4rem;
        &Stripe {
            height: 2.6rem;
        }
    }
}

.orderTotals {
    margin-bottom: 3rem;
    &Item {
        display: flex;
        margin-bottom: .8rem;
        justify-content: space-between;
        &Last {
            font-weight: bold;
            border-bottom: 1px solid #979797;
            padding-bottom: 1rem;
        }
    }
}

.contactPanel {
    border-radius: 1rem;
    padding: 2rem;
    background: #FAFAFA;
    margin-bottom: 2rem;
}

.orderConfirmPage {
    text-align: center !important;
    &Icon {
        color: $primary-green;
        height: 10rem;
        width: 10rem;
    }
    p {
        font-size: 1.6rem;
    }
}

.processing {
    align-items: center;
    background: rgba(255,255,255,0.7);
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    pointer-events: none;
    position: fixed;
    &Loader {
        height: 10rem;
        width: 10rem;
        animation: spin 2s linear infinite;
    }
    &Text {
        font-size: 2rem;
    }
}

.codePage {
    &Right {
        display: none;
    }
}

@keyframes spin { 
    from { 
        transform: rotate(0deg); 
    } to { 
        transform: rotate(360deg); 
    }
}

@include breakpoint-up(lg) {
    .storePage {
        padding: 0;
        &:before {
            background: #FFFFFF;
        }
        &Header {
            background: linear-gradient($primary-dark, $primary) no-repeat left top;
            height: 6rem;
            padding: 1rem;
        }
        &Logo {
            margin-right: .5rem;
            max-width: 100px;
        }
        &Exit {
            align-items: center;
            display: flex;
        }
        &Content {
            border-radius: 0;
            margin: 0 auto;
            padding: 4rem 0;
            max-width: 86rem;
            width: calc(100% - 2rem);
        }
    }
    .mobileTotals {
        display: none;
    }
    .desktopTotals {
        display: block;
    }
    .codePage {
        display: inline-flex;
        margin: 0 -1rem;
        &Left,
        &Right {
            display: block;
            flex: 1;
            margin: 0 1rem;
        }
    }
    .bookPileMobile {
        display: none;
    }
    .orderBook {
        display: flex;
        flex-direction: row-reverse;
        margin: 0 -1rem;
        &Left,
        &Right {
            flex: 1;
            margin: 0 1rem;
        }
    }
 
}