@import '../../styles/mixins';
@import '../../styles/variables';

.login {
    &AppLinks {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 10px 20px 20px;
    }
    &AppsContainer {
        background: lighten($gray-1, 6%);
        margin: 0 -20px 20px;
    }
    &Title {
        margin: 1rem 0 2rem 0;
    }
    &Title,
    &Subtitle {
        font-weight: bold;
        text-align: center;
        font-size: 2rem;
    }
    &Subtitle {
        margin-top: 1rem;
    }
    &Apple,
    &Android {
        align-items: center;
        justify-content: center;
        margin: 0 10px;
    }
    // &Android {
    //     display: flex;
    //     flex: 1;
    //     justify-content: flex-start;
    // }
}

@include breakpoint-up(sm) {
    .login {
        &AppsContainer {
            align-items: center;
            display: flex;
            justify-content: space-between;
            padding: 20px 0;
        }
        &Subtitle {
            flex: 5;
            margin: 0 20px;
            text-align: left !important;
        }
        &AppLinks {
            border: 0;
            flex: 4;
            padding: 0;
            margin: 0 20px;
        }
        &Apple,
        &Android {
            display: flex;
            flex: 1;
            justify-content: flex-start;
            margin: 0 5px 0 0;
        }
        &Android {
            justify-content: flex-end;
            margin: 0 0 0 5px;
        }
    }
}