@each $point in map-keys($grid-breakpoints) {
  $infix: get-infix($point, $grid-breakpoints);
  @include breakpoint-up($point, $grid-breakpoints) {
    .u-text#{$infix}-left { text-align: left!important; }
    .u-text#{$infix}-right { text-align: right!important; }
    .u-text#{$infix}-center { text-align: center!important; }
  }
}

@each $color, $value in $theme-colors {
  .u-text-#{$color} {color: $value!important};
}

.u-bold {
  font-weight: bold;
}

.error {
  color: red;
}

.u-smaller-text {
  font-size: 14px;
}
.u-center {text-align: center}
