a[disabled] {
    pointer-events: none;
}

.link-reverse {
    color: $body-color;
    &:hover {
        color: $primary;
    }
}

.link-underline {
    text-decoration: underline;
}
.btn-reset {
    border: 0;
    padding: 0;
    cursor: pointer;
    &:focus {
        outline: none;
    }
}

.btn-danger {
    background: #A03213!important;
    border-color: #A03213!important;
    color: #fff!important;
    &:hover {
        background: lighten(#A03213, 10%)!important;
    }
}