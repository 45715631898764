@import '../../styles/variables';
@import '../../styles/mixins';

.dropdown {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    &Btn {
        padding: 0;
        border: 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        svg {
            color: darken($gray-2, 10%);
        }
        &:focus {
            outline: none;
        }
        &TitleLeft {
            margin-right: .5rem;
        }
        &TitleRight {
            margin-left: .5rem;
        }
        &IsActive svg {
            color: $primary;
        }
    }


    &Menu {
        background: #fff;
        border-radius: .5rem;
        box-shadow: 0 0.1rem .4rem 0 rgba(0, 0, 0, 0.2);
        z-index: 10;
        position: absolute;
        top: 100;
        right: 0;
        min-width: 16rem;
        max-width: 24rem;
        white-space: nowrap;
        li {
            padding: .5rem 1rem;
            &:not(:last-child) {
                border-bottom: 1px solid lighten($gray-1, 5%);
            }
        }
        button {
            border: 0;
            padding: 0;
            cursor: pointer;
            color: $body-color;
            display: block;
            width: 100%;
            text-align: left;
            &:hover:not(:disabled) {
                color: $primary;
            }
            &:focus {
                outline: none;
            }
            &:disabled {
                opacity: .7;
                cursor: default;
            }
        }
        &Left {
            right: auto;
            left: 0;
        }
    }

}
