.modalContent {
    align-items: center;
    flex-direction: column;
    display: flex;
    text-align: center;
}

.fbImg {
    height: auto;
    margin-bottom: 20px;
    width: 130px;
}