@import '../../styles/mixins';
@import '../../styles/variables';

.loggedOutHeader {
    &Content {
        align-items: center;
        display: flex;
        height: 50px;
        width: 100%;
        justify-content: space-between;
    }
    background: $gray-0;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.3);
    padding: 0 20px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 20;
    &Branding {
        align-items: center;
        color: $body-color;
        display: flex;
        &Logo {
            height: 36px;
        }
        &Title {
            font-size: 20px;
            margin-left: 10px;
            font-weight: bold;
        }
        &:hover {
            color: lighten($body-color, 20%);
        }
    }
    &Actions {
        display: flex;
        &Desktop {
            display: none;
        }
        &ToggleButton {
            border: none;
            cursor: pointer;
            height: 24px;
            outline: none;
            padding: 0;
            &:hover {
                color: lighten($body-color, 20%);
            }
        }
    }
    &Menu {
        border-top: 1px solid $gray-2;
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        padding: 0 0 20px;
        &Action {
            color: $body-color;
            cursor: pointer;
            font-size: 16px;
            margin: 10px 0;
            &:hover {
                color: lighten($body-color, 20%);
            }
            &Bordered {
                border-bottom: 1px solid $gray-2;
                margin: 0;
                padding: 15px 0;
            }
        }
    }
}

@include breakpoint-up(sm) {
    .loggedOutHeader {
        &Content {
            height: 60px;
        }
        
        &Actions {
            &Desktop {
                display: flex;
                &Action {
                    color: $body-color;
                    margin-right: 30px;
                    &:hover {
                        color: lighten($body-color, 20%);
                    }
                }
            }
        }

        &Menu {
            background: $gray-0;
            box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
            position: absolute;
            right: 20px;
            padding: 0 20px;
            top: 50px;
            &Action {
                &Bordered {
                    display: none;
                }
            }
        }
    }
}