@import '../../styles/mixins';

.container {
    align-items: flex-end;
    display: flex;
    margin-bottom: 20px;
}

.savedImage {
    height: 140px;
    margin-right: 10px;
    width: 140px;
}

.previewImage {
    margin-bottom: 10px;
}

.modalContent {
    align-items: center;
    display: flex;
}

.modalControls {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    min-width: 140px;
}

.inputFile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
    z-index: -1;
    & + label {
        background: #ffffff;
        border: 1px solid #80b814;
        border-radius: 9px;
        color: #80b814;
        cursor: pointer;
        display: inline-block;
        font-size: 18px;
        font-weight: bold;
        line-height: normal;
        padding: 6px 20px;
        text-align: center;
        text-transform: uppercase;
        &:hover {
            background: #f2f2f2;
        }
    }
    
    &:focus + label {
        outline: 1px dotted #000;
        outline: -webkit-focus-ring-color auto 5px;
    }
}

@include breakpoint-up(lg) {
    .savedImage {
        margin-right: 0;
        margin-bottom: 10px;
    }

    .titleText {
        display: none;
    }

    .inputFile {
        & + label {
            display: block;
        }
    }

    .container {
        align-items: initial;
        flex-direction: column;
    }
}