.basic-form {
	&__group {
        align-items: flex-start;
        display: flex;
		margin-bottom: 1rem;
		flex-direction: column;
    }
    &__text-label {
		margin-bottom: .2rem;
    }
	&__text-box,
    &__text-select,
    &__text-area,
    &__text-number {
		flex: 1;
		width: 100%;
		border: 1px solid $gray-2;
		color: #4A4A4A;
		font-size: 1.6rem;
		line-height: 1.2;
		padding: .8rem 1rem;
        background: #fff;
        border-radius: 1rem;
		resize: vertical;
		margin-bottom: .5rem;
		transition: border-color .2s ease-in;
		&:focus {
			box-shadow: none;
			outline: none;
			border-color:$primary;
        }
        &:disabled {
            color: lighten(#979797, 20%);
        }
        &--all-caps {
            text-transform: uppercase;
        }
    }
    &__text-number {
        width: 5rem;
    }
    &__text-select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='32px' height='32px' fill='%23999999' viewBox='0 0 32 32'%3e%3cpath d='M4.516,6.742L16,18.227L27.484,6.742L31,10.257L16,25.258L1,10.257L4.516,6.742z'/%3e%3c/svg%3e") no-repeat 97% 50%;
        background-size: 1.4rem;
        &:-moz-focusring {
          color: transparent;
          text-shadow: 0 0 0 #ccc;
        }
    }
	&__group--check {
        position: relative;
        &--with-link {
            display: flex;
            flex-direction: row;
            label {
                margin-right: .5rem;
            }
            span {
                text-decoration: underline;
                cursor: pointer;
                &:hover {
                    color: lighten($body-color, 10%);
                }
            }
        }
	}
	&__check-label {
		padding-left: 2.6rem;
	}
	&__check-box {
		position: absolute;
		opacity: 0;
		z-index: 10;
		~ label:before {
			position: absolute;
			left: 0;
			display: block;
			width: 2rem;
			height: 2rem;
			text-align: center;
			line-height: 2rem;
			pointer-events: none;
			content: "";
			user-select: none;
			background: #fff;
			border: 1px solid darken($gray-1, 20%);
			top: 50%;
			border-radius: 2px;
			transform: translateY(-50%);
			cursor: pointer;
		}
		&:checked ~ label:before {
			content: "";
			color: #fff;
			background: $primary;
			border-color: $primary;
		}
		&:checked ~ label:after {
			content: '';
			position: absolute;
			top: .6rem;
			left: .4rem;
			width: 1.2rem;
			height: .7rem;
			border: 2px solid #fff;
			border-width: 0 0 2px 2px;
			-webkit-transform: rotate(-45deg);
			-ms-transform: rotate(-45deg);
			transform: rotate(-45deg);
        }
        &--with-link {
            display: flex;
        }
	}
    &__hint {
        color: red;
        font-size: 1.2rem;
        margin: -1rem 0 1rem;
	}
    &__errors,
    &__success {
		align-items: center;
		background: lighten(red, 50%);
		border: 1px solid red;
		color: red;
		display: flex;
		font-size: 1.6rem;
		margin: 2rem 0;
		padding: 1rem;
		&__icon {
			margin-right: .7rem;
		}
    }
    &__success {
        background: lighten(green, 70%);
		border: 1px solid green;
		color: green;
    }
}
