@import '../../styles/mixins';

.mobileChangePwdBtn {
    margin-bottom: 3rem;
}
.desktopChangePwdPanel {
    display: none;
}

@include breakpoint-up(md) {
    .contentHolder {
        align-items: flex-start;
        display: flex;
        &Left {
            flex: 1;
            max-width: 310px;
            margin-right: 2rem;
        }
    }
    .mobileChangePwdBtn {
        display: none;
    }
    .desktopChangePwdPanel {
        max-width: 28rem;
        flex-basis: 28rem;
        flex-grow: 1;
        display: block;
        background: #FAFAFA;
        border-radius: 40px;
        padding: 2rem;
    }
}

@include breakpoint-up(lg) {
    .contentHolder {
        &Left {
            display: flex;
            flex: initial;
            max-width: none;
            
        }
    }
    .form {
        margin: 0 20px;
        min-width: 305px;
    }
}